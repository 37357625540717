(function ($) {
  $(document).ready(function () {
    if ($('.breadcrumb_super_cat').length > 0) {
      $('.breadcrumb_super_cat').each(function () {
        var cat_id = $(this).attr('id').replace('breadcrumb_', '');

        if (typeof superCatLinks != 'undefined') {
          var cat_link = superCatLinks[cat_id];

          if (cat_link) {
            $(this).find('a').attr('href', cat_link);
          }
        }
      });
    }
  });
})(jQuery);
